/*____________ Default______________ */
:root {
  --primary-color: #24b34b;
  --btn-text-color-primary: #fff;
  --btn-text-color-green: var(--primary-color);
  --tr-color: #e0ffe0;
  font-family: 'Public Sans';
}

.link {
  text-decoration: none;
}

/* ________________PROTONDX LOGIN_____________________ */
.login-input-box {
  background: rgba(36, 179, 75, 0.6);
  border-radius: 10px;
  width: 400px;
  height: 60px;
  border: none;
  display: block;
  color: #000;
  font-family: 'Public Sans';
  font-weight: 500;
  padding: 10px;
}
.login-input-container {
  margin-top: 25px;
}
.btn-margin {
  margin-top: 30px;
}
.login-btn {
  width: 230px;
  height: 60px;
  border: none;
  background: #24b34b;
  border-radius: 10px;
  color: var(--btn-text-color-primary);
  font-family: 'Public Sans';
  font-weight: 200;
}
.login-box-screen {
  margin-top: 14%;
  margin-left: 50px;
}
.login-title {
  font-style: normal;
  font-size: 40px;
  line-height: 60px;
  color: #878787;
  margin-bottom: 30px;
  font-family: 'Public Sans';
  font-weight: 700;
}
.login-label {
  font-family: 'Public Sans';
  font-weight: 200;
  font-size: 20px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.6);
}
.login-logo {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 66px;
  color: #000000;
  margin-top: 5%;
}

.top-logo {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  margin-top: 20px;
  display: inline-flex;
  flex-basis: 85%;
}

.topmenu {
  display: flex;
  flex-direction: row;
}

.login-image {
  margin-top: 30%;
}

.auth0-login-margin {
  margin-top: 250px;
}
/* ________________PROTONDX NAVBAR___________________ */
/* ________________PROTONDX BUTTON___________________ */
.protondx-btn-primary {
  border: none;
  color: var(--btn-text-color-primary);
  background: var(--primary-color);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Public Sans';
  font-weight: 800;
}

.protondx-btn-danger {
  border: none;
  color: var(--btn-text-color-primary);
  background: #df4759;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Public Sans';
  font-weight: 800;
}
.protondx-btn-white {
  border: none;
  color: var(--btn-text-color-green);
  background: rgba(255, 255, 255, 1);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Public Sans';
  font-weight: 800;
}
.btn-sm {
  width: 150px;
  height: 40px;
}
.btn-lg {
  width: 638px;
  height: 40px;
}

/* ________________PROTONDX DASHBOARD_______________ */
.bar-stats-heading {
  /* font-family: Poppins; */
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  color: #000000;
  font-family: 'Public Sans';
  font-weight: 700;
}
.bar-stats {
  /* font-family: Poppins; */
  font-family: 'Public Sans';
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
  display: block;
  margin-top: 15px;
}
.dashboard-card {
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 100%;
  padding: 50px;
  font-family: 'Public Sans';
  font-weight: 500;
}
.aligncenter {
  align-items: center;
}
.bargraph {
  margin-top: -50px;
}
.piegraph {
  margin-top: -70px;
}
.dashboard-content {
  margin-top: 25px;
}
.pie-card-container {
  margin: 20px 0;
  text-align: center;
}
.pie-label {
  margin-bottom: 8px;
  margin-top: 15%;
}
.pie-card {
  height: 125px;
  width: 125px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-infection {
  background: #dbecfd;
}
.covid-19 {
  background: #d2ffdb;
}
.flu-a {
  background: #e5e5fe;
}
.flu-b {
  background: #ffefe7;
}
.rsv {
  background: #e8f0fb;
}

.hrv {
  background: #ecfbff;
}

/* ________________PROTONDX ORGANIZATION LIST______________ */
.orglist-card {
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  font-family: 'Public Sans';
  font-weight: 500;
}

.dataexport {
  margin-left: 8.5%;
  margin-top: 20px !important;
  font-size: 20px;
}

.separator {
  margin-top: 25px;
}

.th-color {
  color: #8b8b8b;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.tr-color {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.tr-disable {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  background-color: #ebebe4;
}

.org-manager-btn {
  border: none;
  background: none;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);
}

.edit-btn {
  border: none;
  background: none;
  text-decoration: none;
  background-color: var(--primary-color);
}
.non-edit-btn {
  border: none;
  background: none;
  text-decoration: none;
  background: none;
}

/* ____________PROTONDX MODAL_______________ */
.modal-card {
  background: #ffffff;
  border-radius: 10px;
}
.modal-input-box {
  background: #7cd193;
  border-radius: 6px;
  border: none;
  color: var(--btn-text-color-primary);
}
.modal-body {
  margin: 80x;
}
.btn-centre {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* _____________Data Export_____________ */
.export-input {
  width: 157px;
  height: 40px;
  background: #edf6ff;
  border-radius: 6px;
  border: none;
}
.export-label {
  display: inline-flex;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 8px;
}
.export-btn {
  float: right;
  margin-right: 4%;
  margin-bottom: 2%;
}
.export-error{
  padding-left: 8.5%;
  padding-top: 2%;
  color: red;
}

.react-datepicker__input-container input {
  width: 157px;
  height: 40px;
  background: #edf6ff;
  border-radius: 6px;
  border: none;
}

/* ____________________SELECT DROPDOWN____________ */

select {
  border: none;
  color: var(--btn-text-color-primary);
  background: var(--primary-color);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  width: 150px;
  height: 40px;
  align-items: center;
  outline: none;
}

.select-dark {
  width: 157px;
  height: 40px;
  background: #edf6ff;
  border-radius: 6px;
  border: none;
  color: #000;
}

/* ______________NAVIGATION BAR____________ */
.circle {
  height: 5vh;
  width: 5vw;
  border-radius: 10px;
  background-color: #24b34b;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
  font-family: 'Public Sans';
  font-weight: 200;
  border: none;
  flex-basis: 15%;
  align-self: flex-end;
  margin-bottom: 10px;
}

/* ______________AUTH0 DASH BRIDGE____________ */
.auth-bridge {
  position: relative;
  top: 300px;
  left: 300px;
  padding: 20px;
}

/* _______PAGINATION________ */
.paginator {
  margin-top: 15px;
  margin-bottom: 10px;
}

.pagination .page-item a {
  color: var(--primary-color);
}
